.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-trigger {
    position: relative;
    z-index: 3; // Zapewnia, że trigger nie jest zakrywany
  }
}

.dropdown-list {
  position: absolute;
  z-index: 5;
  transform-origin: center;
  animation-duration: 0.1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;

  @keyframes open {
    0% {
      opacity: 0;
      transform: scale(0.7);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes close {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.7);
      visibility: hidden;
    }
  }

  &.init {
    visibility: hidden;
    opacity: 0;
  }

  &.opened {
    animation-name: open;
    visibility: visible;
  }

  &.closed {
    animation-name: close;
  }

  // Pozycjonowanie zgodnie z wymaganiami:
  &.bottom-left {
    top: 50%;
    right: 50%;
    transform: translate(50%, 0%);
    transform-origin: right top;
  }

  &.bottom-right {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    transform-origin: left top;
  }

  &.top-left {
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 0%);
    transform-origin: right bottom;
  }

  &.top-right {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    transform-origin: left bottom;
  }
}
