.expandable {
  width: 100%;
  $timing: var(--timing-expandable);
  // $delay: var(--delay);
  overflow: hidden;
  transition: max-height $timing ease-in-out;

  &.no-transition {
    transition: none;
  }
}
