@use "./src/scss/global.scss" as *;

#footer {
  background-color: $dark;
  padding-top: 64px;
  padding-bottom: 64px;

  overflow-x: visible;
  overflow-y: visible;

  .linked-in {
    color: $primary-dark-mode;

    &:hover {
      color: $primary-dark-mode-hover;
    }
  }

  .social-media {
    justify-content: space-between;
    width: 100%;
    max-width: 375px;
  }

  .tooltip-styling {
    padding: 23px 32px;
    border-radius: 10px;
    background: linear-gradient(159.1deg, rgba(255, 255, 255, 0.1) -0.09%, rgba(255, 255, 255, 0) 171.38%), #120e2f;
  }

  .social-media-link {
    @include text-scaffold(15px, 400, #7f70db, 1.7, 0.01em);
    line-height: 48px;
  }

  .tooltip-title,
  .title {
    text-align: start;
    color: #d0cfd5;
  }

  .children-as-white {
    p {
      color: $dirty-white;
    }
  }
}

#pdfs {
  background-color: $black;
  padding: 21px 17px;

  .title {
    text-align: start;
    color: #d0cfd5;
  }

  .terms-and-conditions {
    max-width: 300px;
    width: 100%;
    @include flex-row(space-between);
  }
}
